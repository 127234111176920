import './../App.css';

function Loader() {
    return (
            <div className="loader">
                <div className="linear-loader-container">
                    <div className="linear-loader"></div>
                </div>
            </div>
    );
}

document.addEventListener("DOMContentLoaded", function () {
    setTimeout(function() {
        document.querySelector('.linear-loader-container').style.display = 'none';
        document.querySelector('.loader').style.display = 'none';
        document.querySelector('.StarCube').style.display = 'block';
    }, 8000);
});

export default Loader;